var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { subheader: "" } },
    [
      _c("v-subheader", { staticClass: "mb-n3 font-weight-bold" }, [
        _vm._v("SUMMARY")
      ]),
      _vm._l(_vm.items, function(item, index) {
        return [
          _c(
            "v-list-item",
            { key: index },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { large: "", color: _vm.iconColor } }, [
                    _vm._v(_vm._s(item.icon))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    staticClass: "mb-1",
                    domProps: { innerHTML: _vm._s(item.title) }
                  }),
                  item.description.length
                    ? _c("v-list-item-subtitle", {
                        domProps: { innerHTML: _vm._s(item.description) }
                      })
                    : _vm._e()
                ],
                1
              ),
              item.enableVerify
                ? _c(
                    "v-list-item-action",
                    [
                      _c("v-spacer"),
                      _c("v-switch", {
                        attrs: { inset: "", color: _vm.switchColor },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  item.verified
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            small: "",
                                            color: _vm.switchColor
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-check")]
                                          ),
                                          _vm._v(" Verified ")
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            small: "",
                                            color: "warning darken-2"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-close")]
                                          ),
                                          _vm._v(" Not Verified ")
                                        ],
                                        1
                                      )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: item.verified,
                          callback: function($$v) {
                            _vm.$set(item, "verified", $$v)
                          },
                          expression: "item.verified"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          index !== _vm.items.length - 1
            ? _c("v-divider", { key: "divider-" + index })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }