<template>
    <v-list subheader>
        <v-subheader class="mb-n3 font-weight-bold">SUMMARY</v-subheader>
        <template v-for="(item, index) in items">
            <v-list-item :key="index">
                <v-list-item-icon>
                    <v-icon large :color="iconColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title
                        class="mb-1"
                        v-html="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-if="item.description.length"
                        v-html="item.description"
                    ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="item.enableVerify">
                    <v-spacer></v-spacer>
                    <v-switch
                        inset
                        :color="switchColor"
                        v-model="item.verified"
                    >
                        <template v-slot:label>
                            <v-chip
                                small
                                v-if="item.verified"
                                :color="switchColor"
                                class="text-uppercase"
                            >
                                <v-icon class="mr-2">mdi-check</v-icon>
                                Verified
                            </v-chip>
                            <v-chip
                                v-else
                                small
                                color="warning darken-2"
                                class="text-uppercase"
                            >
                                <v-icon class="mr-2">mdi-close</v-icon>
                                Not Verified
                            </v-chip>
                        </template>
                    </v-switch>
                </v-list-item-action>
            </v-list-item>
            <v-divider
                :key="`divider-${index}`"
                v-if="index !== items.length - 1"
            ></v-divider>
        </template>
    </v-list>
</template>
<script>
export default {
    name: "rental-employment-summary",
    components: {},
    props: {
        iconColor: {
            type: String,
            required: false,
            default: "primary",
        },
        switchColor: {
            type: String,
            required: false,
            default: "secondary",
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>